const items_data = [
	// bids data
	{
		id: '1',
		image: '/images/products/item_1.jpg',
		title: 'I will create shopify store,shopify website and dropshipping store',
		price: 250,
		likes: 159,
		text: 'Neque aut veniam consectetur magnam libero, natus eius numquam reprehenderit hic at, excepturi repudiandae magni optio odio doloribus? Facilisi lobortisal morbi fringilla urna amet sed ipsum.',
		creatorImage: '/images/avatars/avatar_7.jpg',
		ownerImage: '/images/avatars/avatar_1.jpg',
		creatorname: '@creative_world',
		ownerName: 'Jonathan Donovan',
		owner: {
			id: "9",
			name: "Jonothan Donatello",
			image: "/images/avatars/owner_3.png",
		},
		auction_timer: '636234213',
		category: "web",
	},
	{
		id: '2',
		image: '/images/products/item_2.jpg',
		title: 'I will create shopify dropshipping ecommerce stores design making money on autopilot',
		price: 155,
		likes: 159,
		creatorImage: '/images/avatars/avatar_7.jpg',
		ownerImage: '/images/avatars/avatar_1.jpg',
		creatorname: '@creative_world',
		ownerName: 'Jonathan Donovan',
		owner: {
			id: "8",
			name: "Jonathan Donatello",
			image: "/images/avatars/owner_3.png",
		},
		auction_timer: '636234213',
		text: 'Neque aut veniam consectetur magnam libero, natus eius numquam reprehenderit hic at, excepturi repudiandae magni optio odio doloribus? Facilisi lobortisal morbi fringilla urna amet sed ipsum.',
		category: "web",
	},
	{
		id: '3',
		image: '/images/products/item_3.jpg',
		title: 'I will build shopify store or dropshipping ecommerce store',
		price: 150,
		likes: 159,
		creatorImage: '/images/avatars/avatar_7.jpg',
		ownerImage: '/images/avatars/avatar_1.jpg',
		creatorname: '@creative_world',
		ownerName: 'Jonathan Donovan',
		owner: {
			id: "7",
			name: "Jonathan Donatello",
			image: "/images/avatars/owner_3.png",
		},
		auction_timer: '636234213',
		text: 'Neque aut veniam consectetur magnam libero, natus eius numquam reprehenderit hic at, excepturi repudiandae magni optio odio doloribus? Facilisi lobortisal morbi fringilla urna amet sed ipsum.',
		category: "web",
	},
	{
		id: '4',
		image: '/images/products/item_4.jpg',
		title: 'I will design and develop a fully responsive wordpress website',
		price: 100,
		likes: 159,
		creatorImage: '/images/avatars/avatar_7.jpg',
		ownerImage: '/images/avatars/avatar_1.jpg',
		creatorname: '@creative_world',
		ownerName: 'Jonathan Donovan',
		owner: {
			id: "6",
			name: "Jonathan Donatello",
			image: "/images/avatars/owner_3.png",
		},
		auction_timer: '636234213',
		text: 'Neque aut veniam consectetur magnam libero, natus eius numquam reprehenderit hic at, excepturi repudiandae magni optio odio doloribus? Facilisi lobortisal morbi fringilla urna amet sed ipsum.',
		category: "web",
	},
	{
		id: '5',
		image: '/images/products/item_5.jpg',
		title: 'I will build your professional wix website with unlimited revisions',
		price: 125,
		likes: 159,
		creatorImage: '/images/avatars/avatar_7.jpg',
		ownerImage: '/images/avatars/avatar_1.jpg',
		creatorname: '@creative_world',
		ownerName: 'Jonathan Donovan',
		owner: {
			id: "5",
			name: "Jonathan Donatello",
			image: "/images/avatars/owner_3.png",
		},
		auction_timer: '636234213',
		text: 'Neque aut veniam consectetur magnam libero, natus eius numquam reprehenderit hic at, excepturi repudiandae magni optio odio doloribus? Facilisi lobortisal morbi fringilla urna amet sed ipsum.',
		category: "web",
	},
	{
		id: '6',
		image: '/images/products/item_6.jpg',
		title: 'I will develop, design and kickstart your ecommerce shopify website',
		price: 135,
		likes: 159,
		creatorImage: '/images/avatars/avatar_7.jpg',
		ownerImage: '/images/avatars/avatar_1.jpg',
		creatorname: '@creative_world',
		ownerName: 'Jonathan Donovan',
		owner: {
			id: "4",
			name: "Jonathan Donatello",
			image: "/images/avatars/owner_3.png",
		},
		auction_timer: '636234213',
		text: 'Neque aut veniam consectetur magnam libero, natus eius numquam reprehenderit hic at, excepturi repudiandae magni optio odio doloribus? Facilisi lobortisal morbi fringilla urna amet sed ipsum.',
		category: "web",
	},
	{
		id: '7',
		image: '/images/products/item_7.jpg',
		creatorImage: '/images/avatars/creator_4.png',
		ownerImage: '/images/avatars/owner_4.png',
		price: 155,
		title: 'I will create high converting shopify dropshipping store or website',
		likes: 100,
		creatorname: '@creative_world',
		ownerName: 'Jonathan Donovan',
		owner: {
			id: "3",
			name: "Jonathan Donatello",
			image: "/images/avatars/owner_3.png",
		},
		auction_timer: '636234213',
		text: 'Neque aut veniam consectetur magnam libero, natus eius numquam reprehenderit hic at, excepturi repudiandae magni optio odio doloribus? Facilisi lobortisal morbi fringilla urna amet sed ipsum.',
		category: "logo",
	},
	{
		id: '8',
		image: '/images/products/item_8.jpg',
		creatorImage: '/images/avatars/creator_1.png',
		ownerImage: '/images/avatars/owner_1.png',
		price: 75,
		title: 'I will design or develop webflow website, figma to webflow, webflow expert',
		likes: 25,
		creatorname: '@creative_world',
		ownerName: 'Jonathan Donovan',
		owner: {
			id: "2",
			name: "Jonathan Donatello",
			image: "/images/avatars/owner_3.png",
		},
		auction_timer: '636234213',
		text: 'Neque aut veniam consectetur magnam libero, natus eius numquam reprehenderit hic at, excepturi repudiandae magni optio odio doloribus? Facilisi lobortisal morbi fringilla urna amet sed ipsum.',
		category: "web",
	},
	{
		id: '9',
		image: '/images/products/item_9.jpg',
		creatorImage: '/images/avatars/creator_4.png',
		ownerImage: '/images/avatars/owner_4.png',
		price: 100,
		title: 'I will design or redesign professional wix or squarespace website',
		likes: 100,
		creatorname: '@creative_world',
		ownerName: 'Jonathan Donovan',
		owner: {
			id: "1",
			name: "Jonathan Donatello",
			image: "/images/avatars/owner_3.png",
		},
		auction_timer: '636234213',
		text: 'Neque aut veniam consectetur magnam libero, natus eius numquam reprehenderit hic at, excepturi repudiandae magni optio odio doloribus? Facilisi lobortisal morbi fringilla urna amet sed ipsum.',
		category: "platform",
	},
	{
		id: '10',
		image: '/images/products/item_10.jpg',
		creatorImage: '/images/avatars/creator_2.png',
		ownerImage: '/images/avatars/owner_7.png',
		price: 110,
		title: 'I will create a modern and unique responsive wordpress website',
		likes: 15,
		creatorname: '@creative_world',
		ownerName: 'Jonathan Donovan',
		owner: {
			id: "8",
			name: "Jonathan Donatello",
			image: "/images/avatars/owner_3.png",
		},
		auction_timer: '636234213',
		text: 'Neque aut veniam consectetur magnam libero, natus eius numquam reprehenderit hic at, excepturi repudiandae magni optio odio doloribus? Facilisi lobortisal morbi fringilla urna amet sed ipsum.',
		category: "platform",
	},
	{
		id: '11',
		image: '/images/products/item_11.jpg',
		creatorImage: '/images/avatars/creator_8.png',
		ownerImage: '/images/avatars/owner_5.png',
		price: 50,
		title: 'I will design creative clickfunnels 2 0 sales funnel landing page',
		likes: 34,
		creatorname: '@creative_world',
		ownerName: 'Jonathan Donovan',
		owner: {
			id: "7",
			name: "Jonathan Donatello",
			image: "/images/avatars/owner_3.png",
		},
		auction_timer: '636234213',
		text: 'Neque aut veniam consectetur magnam libero, natus eius numquam reprehenderit hic at, excepturi repudiandae magni optio odio doloribus? Facilisi lobortisal morbi fringilla urna amet sed ipsum.',
		category: "logo",
	},
	{
		id: '12',
		image: '/images/products/item_12_lg.jpg',
		creatorImage: '/images/avatars/creator_3.png',
		ownerImage: '/images/avatars/owner_3.png',
		price: 120,
		likes: 260,
		creatorname: '@creative_world',
		ownerName: 'Jonathan Donovan',
		owner: {
			id: "6",
			name: "Jonathan Donatello",
			image: "/images/avatars/owner_3.png",
		},
		auction_timer: '636234213',
		text: 'Neque aut veniam consectetur magnam libero, natus eius numquam reprehenderit hic at, excepturi repudiandae magni optio odio doloribus? Facilisi lobortisal morbi fringilla urna amet sed ipsum.',
		title: 'I will build your real estate website and integrate mls to idx',
		category: "logo",
	},
	{
		id: '13',
		image: '/images/products/item_13_lg.jpg',
		creatorImage: '/images/avatars/creator_3.png',
		ownerImage: '/images/avatars/owner_3.png',
		price: 125,
		likes: 260,
		creatorname: '@creative_world',
		ownerName: 'Jonathan Donovan',
		owner: {
			id: "5",
			name: "Jonathan Donatello",
			image: "/images/avatars/owner_3.png",
		},
		auction_timer: '636234213',
		text: 'Neque aut veniam consectetur magnam libero, natus eius numquam reprehenderit hic at, excepturi repudiandae magni optio odio doloribus? Facilisi lobortisal morbi fringilla urna amet sed ipsum.',
		title: 'I will design landing page squeeze page using elementor, swipe page',
		category: "logo",
	},
	{
		id: '14',
		image: '/images/products/item_14_lg.jpg',
		creatorImage: '/images/avatars/creator_3.png',
		ownerImage: '/images/avatars/owner_3.png',
		price: 95,
		likes: 260,
		creatorname: '@creative_world',
		ownerName: 'Jonathan Donovan',
		owner: {
			id: "4",
			name: "Jonathan Donatello",
			image: "/images/avatars/owner_3.png",
		},
		auction_timer: '636234213',
		text: 'Neque aut veniam consectetur magnam libero, natus eius numquam reprehenderit hic at, excepturi repudiandae magni optio odio doloribus? Facilisi lobortisal morbi fringilla urna amet sed ipsum.',
		title: 'I will do product research for amazon private label fba',
		category: "seo",
	},
	{
		id: '15',
		image: '/images/products/item_15_lg.jpg',
		creatorImage: '/images/avatars/creator_3.png',
		ownerImage: '/images/avatars/owner_3.png',
		price: 95,
		likes: 260,
		creatorname: '@creative_world',
		ownerName: 'Jonathan Donovan',
		owner: {
			id: "3",
			name: "Jonathan Donatello",
			image: "/images/avatars/owner_3.png",
		},
		auction_timer: '636234213',
		text: 'Neque aut veniam consectetur magnam libero, natus eius numquam reprehenderit hic at, excepturi repudiandae magni optio odio doloribus? Facilisi lobortisal morbi fringilla urna amet sed ipsum.',
		title: 'I will dramatically increase your shopify store website speed',
		category: "marketing",
	},
	{
		id: '16',
		image: '/images/products/item_16_lg.jpg',
		creatorImage: '/images/avatars/creator_3.png',
		ownerImage: '/images/avatars/owner_3.png',
		price: 100,
		likes: 260,
		creatorname: '@creative_world',
		ownerName: 'Jonathan Donovan',
		owner: {
			id: "2",
			name: "Jonathan Donatello",
			image: "/images/avatars/owner_3.png",
		},
		auction_timer: '636234213',
		text: 'Neque aut veniam consectetur magnam libero, natus eius numquam reprehenderit hic at, excepturi repudiandae magni optio odio doloribus? Facilisi lobortisal morbi fringilla urna amet sed ipsum.',
		title: 'I will create shopify dropshipping store shopify website and single product store',
		category: "platform",
	},
	{
		id: '17',
		image: '/images/products/item_17.jpg',
		creatorImage: '/images/avatars/creator_2.png',
		ownerImage: '/images/avatars/owner_2.png',
		price: 5,
		title: 'I will design awesome app screenshots for play store and app store',
		likes: 334,
		creatorname: '@creative_world',
		ownerName: 'Jonathan Donovan',
		owner: {
			id: "1",
			name: "Jonathan Donatello",
			image: "/images/avatars/owner_3.png",
		},
		auction_timer: '636234213',
		text: 'Neque aut veniam consectetur magnam libero, natus eius numquam reprehenderit hic at, excepturi repudiandae magni optio odio doloribus? Facilisi lobortisal morbi fringilla urna amet sed ipsum.',
		category: "logo",
	},
	{
		id: '18',
		image: '/images/products/item_18.jpg',
		creatorImage: '/images/avatars/creator_9.png',
		ownerImage: '/images/avatars/owner_8.png',
		price: 10,
		title: 'I will do app UI UX design wireframe design website UI UX design and prototype',
		likes: 52,
		creatorname: '@creative_world',
		ownerName: 'Jonathan Donovan',
		owner: {
			id: "12",
			name: "Jonathan Donatello",
			image: "/images/avatars/owner_3.png",
		},
		auction_timer: '636234213',
		text: 'Neque aut veniam consectetur magnam libero, natus eius numquam reprehenderit hic at, excepturi repudiandae magni optio odio doloribus? Facilisi lobortisal morbi fringilla urna amet sed ipsum.',
		category: "logo",
	},
	{
		id: '19',
		image: '/images/products/item_19.jpg',
		creatorImage: '/images/avatars/creator_6.png',
		ownerImage: '/images/avatars/owner_4.png',
		price: 10,
		title: 'I will design stunning mobile app UI UX design using figma, xd',
		likes: 90,
		creatorname: '@creative_world',
		ownerName: 'Jonathan Donovan',
		owner: {
			id: "11",
			name: "Jonathan Donatello",
			image: "/images/avatars/owner_3.png",
		},
		auction_timer: '636234213',
		text: 'Neque aut veniam consectetur magnam libero, natus eius numquam reprehenderit hic at, excepturi repudiandae magni optio odio doloribus? Facilisi lobortisal morbi fringilla urna amet sed ipsum.',
		category: "logo",
	},
	{
		id: '20',
		image: '/images/products/item_20_square.jpg',
		creatorImage: '/images/avatars/avatar_7.jpg',
		ownerImage: '/images/avatars/avatar_1.jpg',
		price: 10,
		likes: 60,
		creatorname: '@creative_world',
		ownerName: 'Jonathan Donovan',
		owner: {
			id: "13",
			name: "Jonathan Donatello",
			image: "/images/avatars/owner_3.png",
		},
		auction_timer: '636234213',
		text: 'Neque aut veniam consectetur magnam libero, natus eius numquam reprehenderit hic at, excepturi repudiandae magni optio odio doloribus? Facilisi lobortisal morbi fringilla urna amet sed ipsum.',
		title: 'I will design uiux or prototype wireframe for mobile',
		category: "logo",
	},
	{
		id: '21',
		image: '/images/products/item_15_lg.jpg',
		creatorImage: '/images/avatars/creator_3.png',
		ownerImage: '/images/avatars/owner_3.png',
		price: 20,
		likes: 60,
		creatorname: '@creative_world',
		ownerName: 'Jonathan Donovan',
		owner: {
			id: "14",
			name: "Jonathan Donatello",
			image: "/images/avatars/owner_3.png",
		},
		auction_timer: '636234213',
		text: 'Neque aut veniam consectetur magnam libero, natus eius numquam reprehenderit hic at, excepturi repudiandae magni optio odio doloribus? Facilisi lobortisal morbi fringilla urna amet sed ipsum.',
		title: 'I will design mobile or web UI in figma or adobe xd',
		category: "web",
	},
	{
		id: '22',
		image: '/images/products/item_8.jpg',
		creatorImage: '/images/avatars/creator_3.png',
		ownerImage: '/images/avatars/owner_3.png',
		price: 295,
		likes: 26,
		creatorname: '@creative_world',
		ownerName: 'Jonathan Donovan',
		owner: {
			id: "15",
			name: "Jonathan Donatello",
			image: "/images/avatars/owner_3.png",
		},
		auction_timer: '636234213',
		text: 'Neque aut veniam consectetur magnam libero, natus eius numquam reprehenderit hic at, excepturi repudiandae magni optio odio doloribus? Facilisi lobortisal morbi fringilla urna amet sed ipsum.',
		title: 'I will design beautiful wix websites with unlimited revisions',
		category: "web",
	},
	{
		id: '23',
		image: '/images/products/item_23_sm.jpg',
		creatorImage: '/images/avatars/creator_3.png',
		ownerImage: '/images/avatars/owner_3.png',
		price: 150,
		likes: 260,
		creatorname: '@creative_world',
		ownerName: 'Jonathan Donovan',
		owner: {
			id: "8",
			name: "Jonathan Donatello",
			image: "/images/avatars/owner_3.png",
		},
		auction_timer: '636234213',
		text: 'Neque aut veniam consectetur magnam libero, natus eius numquam reprehenderit hic at, excepturi repudiandae magni optio odio doloribus? Facilisi lobortisal morbi fringilla urna amet sed ipsum.',
		title: 'I will build shopify dropshipping store, shopify website design',
		category: "platform",
	},
	{
		id: '24',
		image: '/images/products/item_3.jpg',
		creatorImage: '/images/avatars/creator_3.png',
		ownerImage: '/images/avatars/owner_3.png',
		price: 140,
		likes: 260,
		creatorname: '@creative_world',
		ownerName: 'Jonathan Donovan',
		owner: {
			id: "7",
			name: "Jonathan Donatello",
			image: "/images/avatars/owner_3.png",
		},
		auction_timer: '636234213',
		text: 'Neque aut veniam consectetur magnam libero, natus eius numquam reprehenderit hic at, excepturi repudiandae magni optio odio doloribus? Facilisi lobortisal morbi fringilla urna amet sed ipsum.',
		title: 'I will design and develop fully responsive website',
		category: "web",
	},
	{
		id: '25',
		image: '/images/products/item_25.jpg',
		creatorImage: '/images/avatars/avatar_7.jpg',
		ownerImage: '/images/avatars/avatar_1.jpg',
		price: 5,
		likes: 260,
		creatorname: 'Origin Morish',
		ownerName: 'Jonathan Donovan',
		owner: {
			id: "6",
			name: "Jonathan Donatello",
			image: "/images/avatars/owner_3.png",
		},
		auction_timer: '636234213',
		text: 'Neque aut veniam consectetur magnam libero, natus eius numquam reprehenderit hic at, excepturi repudiandae magni optio odio doloribus? Facilisi lobortisal morbi fringilla urna amet sed ipsum.',
		title: 'I will do shopify speed optimization and increase shopify score',
		category: "social",
	},
	{
		id: '26',
		image: '/images/products/item_1.jpg',
		title: 'I will do shopify speed optimization for mobile and desktop',
		price: 10,
		likes: 159,
		text: 'Neque aut veniam consectetur magnam libero, natus eius numquam reprehenderit hic at, excepturi repudiandae magni optio odio doloribus? Facilisi lobortisal morbi fringilla urna amet sed ipsum.',
		creatorImage: '/images/avatars/avatar_7.jpg',
		ownerImage: '/images/avatars/avatar_1.jpg',
		creatorname: '@creative_world',
		ownerName: 'Jonathan Donovan',
		owner: {
			id: "5",
			name: "Jonathan Donatello",
			image: "/images/avatars/owner_3.png",
		},
		auction_timer: '636234213',
		category: "seo",
	},
	{
		id: '27',
		image: '/images/products/item_27_square.jpg',
		creatorImage: '/images/avatars/creator_3.png',
		ownerImage: '/images/avatars/owner_3.png',
		price: 20,
		likes: 260,
		creatorname: 'Lazy Panda',
		ownerName: 'Jonathan Donovan',
		owner: {
			id: "4",
			name: "Jonathan Donatello",
			image: "/images/avatars/owner_3.png",
		},
		auction_timer: '636234213',
		text: 'Neque aut veniam consectetur magnam libero, natus eius numquam reprehenderit hic at, excepturi repudiandae magni optio odio doloribus? Facilisi lobortisal morbi fringilla urna amet sed ipsum.',
		title: 'I will do shopify speed optimization and increase shopify speed',
		category: "seo",
	},
	{
		id: '28',
		image: '/images/products/item_28_square.jpg',
		creatorImage: '/images/avatars/creator_3.png',
		ownerImage: '/images/avatars/owner_3.png',
		price: 25,
		likes: 260,
		creatorname: 'NFT stars',
		ownerName: 'Jonathan Donovan',
		owner: {
			id: "3",
			name: "Jonathan Donatello",
			image: "/images/avatars/owner_3.png",
		},
		auction_timer: '636234213',
		text: 'Neque aut veniam consectetur magnam libero, natus eius numquam reprehenderit hic at, excepturi repudiandae magni optio odio doloribus? Facilisi lobortisal morbi fringilla urna amet sed ipsum.',
		title: 'I will speed up wordpress website for google pagespeed insights',
		category: "platform",
	},
	{
		id: '29',
		image: '/images/products/item_29_square.jpg',
		creatorImage: '/images/avatars/creator_3.png',
		ownerImage: '/images/avatars/owner_3.png',
		price: 20,
		likes: 260,
		creatorname: 'Origin Morish',
		ownerName: 'Jonathan Donovan',
		owner: {
			id: "2",
			name: "Jonathan Donatello",
			image: "/images/avatars/owner_3.png",
		},
		auction_timer: '636234213',
		text: 'Neque aut veniam consectetur magnam libero, natus eius numquam reprehenderit hic at, excepturi repudiandae magni optio odio doloribus? Facilisi lobortisal morbi fringilla urna amet sed ipsum.',
		title: 'I will do shopify speed optimization and increase shopify score',
		category: "seo",
	},
	{
		id: '30',
		image: '/images/products/item_30_square.jpg',
		creatorImage: '/images/avatars/creator_3.png',
		ownerImage: '/images/avatars/owner_3.png',
		price: 50,
		likes: 26,
		creatorname: 'Lazy Panda',
		ownerName: 'Jonathan Donovan',
		owner: {
			id: "1",
			name: "Jonathan Donatello",
			image: "/images/avatars/owner_3.png",
		},
		auction_timer: '636234213',
		text: 'Neque aut veniam consectetur magnam libero, natus eius numquam reprehenderit hic at, excepturi repudiandae magni optio odio doloribus? Facilisi lobortisal morbi fringilla urna amet sed ipsum.',
		title: 'I will do your shopify custom coding and shopify bug fix',
		category: "platform",
	},
	{
		id: '31',
		image: '/images/products/item_31_square.jpg',
		creatorImage: '/images/avatars/creator_3.png',
		ownerImage: '/images/avatars/owner_3.png',
		price: 125,
		likes: 260,
		creatorname: 'NFT stars',
		ownerName: 'Jonathan Donovan',
		owner: {
			id: "14",
			name: "Jonathan Donatello",
			image: "/images/avatars/owner_3.png",
		},
		auction_timer: '636234213',
		text: 'Neque aut veniam consectetur magnam libero, natus eius numquam reprehenderit hic at, excepturi repudiandae magni optio odio doloribus? Facilisi lobortisal morbi fringilla urna amet sed ipsum.',
		title: 'I will create shopify dropshipping store',
		category: "platform",
	},
	{
		id: '32',
		image: '/images/products/item_32_square.jpg',
		creatorImage: '/images/avatars/creator_3.png',
		ownerImage: '/images/avatars/owner_3.png',
		price: 80,
		likes: 260,
		creatorname: 'Origin Morish',
		ownerName: 'Jonathan Donovan',
		owner: {
			id: "13",
			name: "Jonathan Donatello",
			image: "/images/avatars/owner_3.png",
		},
		auction_timer: '636234213',
		text: 'Neque aut veniam consectetur magnam libero, natus eius numquam reprehenderit hic at, excepturi repudiandae magni optio odio doloribus? Facilisi lobortisal morbi fringilla urna amet sed ipsum.',
		title: 'I will set up a branded shopify website shopify store',
		category: "platform",
	},

	{
		id: '33',
		image: '/images/nft-game/nft_game_character_1.png',
		creatorImage: '/images/avatars/creator_3.png',
		ownerImage: '/images/avatars/owner_3.png',
		price: 95,
		likes: 260,
		creatorname: 'Origin Morish',
		ownerName: 'Jonathan Donovan',
		owner: {
			id: "12",
			name: "Jonathan Donatello",
			image: "/images/avatars/owner_3.png",
		},
		auction_timer: '636234213',
		text: 'Neque aut veniam consectetur magnam libero, natus eius numquam reprehenderit hic at, excepturi repudiandae magni optio odio doloribus? Facilisi lobortisal morbi fringilla urna amet sed ipsum.',
		title: 'I will design wix website or redesign wix business website',
		category: "platform",
	},
	{
		id: '34',
		image: '/images/nft-game/nft_game_character_2.png',
		creatorImage: '/images/avatars/creator_3.png',
		ownerImage: '/images/avatars/owner_3.png',
		price: 80,
		likes: 260,
		creatorname: 'Origin Morish',
		ownerName: 'Jonathan Donovan',
		owner: {
			id: "11",
			name: "Jonathan Donatello",
			image: "/images/avatars/owner_3.png",
		},
		auction_timer: '636234213',
		text: 'Neque aut veniam consectetur magnam libero, natus eius numquam reprehenderit hic at, excepturi repudiandae magni optio odio doloribus? Facilisi lobortisal morbi fringilla urna amet sed ipsum.',
		title: 'I will setup shopify etsy print on demand store or website using printful',
		category: "web",
	},
	{
		id: '35',
		image: '/images/nft-game/nft_game_character_3.png',
		creatorImage: '/images/avatars/creator_3.png',
		ownerImage: '/images/avatars/owner_3.png',
		price: 95,
		likes: 60,
		creatorname: 'Origin Morish',
		ownerName: 'Jonathan Donovan',
		owner: {
			id: "10",
			name: "Jonathan Donatello",
			image: "/images/avatars/owner_3.png",
		},
		auction_timer: '636234213',
		text: 'Neque aut veniam consectetur magnam libero, natus eius numquam reprehenderit hic at, excepturi repudiandae magni optio odio doloribus? Facilisi lobortisal morbi fringilla urna amet sed ipsum.',
		title: 'I will design shopify store shopify website dropshipping store',
		category: "web",
	},
	{
		id: '36',
		image: '/images/nft-game/nft_game_character_4.png',
		creatorImage: '/images/avatars/creator_3.png',
		ownerImage: '/images/avatars/owner_3.png',
		price: 100,
		likes: 89,
		creatorname: 'Origin Morish',
		ownerName: 'Jonathan Donovan',
		owner: {
			id: "9",
			name: "Jonathan Donatello",
			image: "/images/avatars/owner_3.png",
		},
		auction_timer: '636234213',
		text: 'Neque aut veniam consectetur magnam libero, natus eius numquam reprehenderit hic at, excepturi repudiandae magni optio odio doloribus? Facilisi lobortisal morbi fringilla urna amet sed ipsum.',
		title: 'I will create amazon affiliate marketing autopilot website with autoblog',
		category: "marketing",
	},
	{
		id: '37',
		image: '/images/nft-game/nft_game_character_5.png',
		creatorImage: '/images/avatars/creator_3.png',
		ownerImage: '/images/avatars/owner_3.png',
		price: 175,
		likes: 34,
		creatorname: 'Origin Morish',
		ownerName: 'Jonathan Donovan',
		owner: {
			id: "8",
			name: "Jonathan Donatello",
			image: "/images/avatars/owner_3.png",
		},
		auction_timer: '636234213',
		text: 'Neque aut veniam consectetur magnam libero, natus eius numquam reprehenderit hic at, excepturi repudiandae magni optio odio doloribus? Facilisi lobortisal morbi fringilla urna amet sed ipsum.',
		title: 'I will design professional responsive HTML email templates',
		category: "logo",
	},
];

export { items_data };
